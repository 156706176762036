<template>
  <my-case-section-core no-padding>
    <template #header>
      <p
        v-if="hasRentalPlan"
        class="small-text mb-0">
        Drop your returns in the mail <span
          v-if="showTodayReturns"
          class="semi-bold">
          today.
        </span>
        <span v-else>within 24 hours of receiving your new Case.</span>
      </p>
      <p
        v-else
        class="small-text mb-0">
        Drop your returns in the mail today.
      </p>
      <text-link
        v-if="active && hasRentalPlan"
        variant="primary"
        class="mx-1"
        @click="setMyCaseFlyoutName('edit-returns')">
        Edit Return Items
      </text-link>
    </template>
    <template #body>
      <div class="border-top mt-3 mt-md-4 py-3 py-md-4 px-4 px-md-5">
        <div
          v-for="item in toReturnItems"
          :key="item.id"
          :item="item">
          <my-case-item
            :item="item.item"
            hide-price>
            <template #itemInfoSlot>
              <div
                v-if="getStyleColor(item.item).style.hasBelt"
                class="fine-print font-italic my-3 text-pewter">
                Don't forget to include this item's matching belt with your return
              </div>
            </template>
            <div class="d-flex my-2 flex-shrink-0">
              <div
                v-if="heldForPurchase(item)"
                class="text-success">
                <strong>Held for purchase</strong>
              </div>
              <div
                v-else
                class="d-flex align-items-center">
                <my-case-item-review-buttons
                  v-if="hasRentalPlan"
                  :item="item"/>
                <sequin-button
                  v-if="hasRentalPlan"
                  small
                  variant="secondary"
                  @click="openPurchaseReturningItemFlyout(item)">
                  Buy
                </sequin-button>
              </div>
            </div>
          </my-case-item>
        </div>
      </div>
      <FedExCaseCTA
        v-if="!isMoovnEligible && !recentFedexPickup"
        class="mt-3"
        source="returns"/>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseItemReviewButtons from './MyCaseItemReviewButtons'
import SequinButton from '../../global/sequin/SequinButton'
import TextLink from '../../global/sequin/TextLink'
import FedExCaseCTA from '../fedEx/FedExCaseCTA.vue'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions, storeToRefs } from 'pinia'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import { toMonthDayString } from '@/utils/stringParsing.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useFedExOptInStore } from '@/stores/fedExOptIn'
import { useReturnCarrier } from '@/composables/returnCarrier.js'

export default {
  components: {
    MyCaseItem,
    MyCaseItemReviewButtons,
    MyCaseSectionCore,
    FedExCaseCTA,
    SequinButton,
    TextLink
  },
  mixins: [MyCaseMixin, StyleColorMixin],
  setup () {
    const { isMoovnEligible } = useReturnCarrier()
    const { recentFedexPickup } = storeToRefs(useFedExOptInStore())
    return {
      isMoovnEligible,
      recentFedexPickup
    }
  },
  computed: {
    ...mapState('account', [
      'returnLabelLink'
    ]),
    showTodayReturns () {
      return this.deliveredItems.length > 0 || this.primaryCaseState === 'awaiting-returns'
    }
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapActions(useFedExOptInStore, [
      'optIntoFedEx'
    ]),
    toMonthDayString,
    openPurchaseReturningItemFlyout (item) {
      this.setMyCaseFlyoutContext({ itemToPurchase: item })
      this.setMyCaseFlyoutName('purchase-returning-item')
    },
    getStyleColor (item) {
      return this.styleColorsMap[item.styleColor]
    }
  }
}
</script>
