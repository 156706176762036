<template>
  <my-case-section-core>
    <template #body>
      <div class="mt-3 px-2 px-md-3">
        <div class="pb-3">
          <div
            v-for="(step, idx) in returnSteps"
            :key="`return-step-${idx}`"
            class="d-flex align-items-center mb-4">
            <div class="circle-step me-3">
              <h6 class="mb-0">
                {{ idx + 1 }}
              </h6>
            </div>
            <p
              class="small-text mb-0"
              v-html="step"/>
          </div>
        </div>
      </div>
    </template>
    <template #cta>
      <FedExCaseCTA
        v-if="!isMoovnEligible && !recentFedexPickup"
        source="returns"/>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import { toMonthDayString } from '@/utils/stringParsing.js'
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions, storeToRefs } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import FedExCaseCTA from '../fedEx/FedExCaseCTA.vue'
import { useReturnCarrier } from '@/composables/returnCarrier.js'
import { useFedExOptInStore } from '@/stores/fedExOptIn'

export default {
  components: {
    MyCaseSectionCore,
    FedExCaseCTA
  },
  mixins: [MyCaseMixin],
  setup () {
    const { isMoovnEligible } = useReturnCarrier()
    const { recentFedexPickup } = storeToRefs(useFedExOptInStore())
    return {
      isMoovnEligible,
      recentFedexPickup
    }
  },
  computed: {
    returnStepsNoSwapAvailable () {
      return [
        `Keep your items, return label, and <span class="semi-bold">mailer</span> ${this.nextShipmentDate ? `until your next Case Swap unlocks on <span class="semi-bold">${toMonthDayString(this.nextShipmentDate, 'short')}</span>` : ''}.`,
        'Once it unlocks, click <span class="semi-bold">Swap</span> above to select the items you\'d like to return and the new items you\'d like to rent.',
        '<span class="semi-bold">Reuse the mailer</span> to send your returns back within 24 hours of receiving your new Case.'
      ]
    },
    returnStepsSwapAvailable () {
      return [
        'When you\'re ready to build a new case, click <span class="semi-bold">Swap</span> above to select the items you\'d like to return and the new items you\'d like to rent.',
        'You can keep the clothes that you currently have until you receive your new items.',
        '<span class="semi-bold">Reuse the mailer</span> to send your returns back within 24 hours of receiving your new Case.'
      ]
    },
    returnSteps () {
      return this.membershipDetails.allowedShipments === 0 ? this.returnStepsNoSwapAvailable : this.returnStepsSwapAvailable
    }
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ])
  }
}
</script>

<style lang="scss" scoped>
.circle-step {
  background-color: $velvet;
  border-radius: 50%;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
</style>
